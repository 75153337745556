<template>
  <div class='wameed-dashboard-page-content'>
    <page-header
      :title="$t('settings.send_notification')"
      :paths="[{title:$t('settings.title'),link:'settings'},{title:$t('notifications.title'),link:'system-notifications'}]"

    />

    <section class='wameed-dashboard-page-content_body products_create' :key='key'>
      <b-row class='px-2'>
        <b-col lg='12' md='12' class='px-0'>
          <b-card no-body class='wameed-card'>
            <b-card-header class='align-items-start tab-title'>
              <h5 class='text-med-20 text-font-main mb-0'>
                {{ $t('settings.send_notification') }}
              </h5>
            </b-card-header>
            <wameed-form @onSubmit='submit'>
              <div slot-scope='{ invalid }'>
                <b-card-body
                  class='position-relative justify-content-between px-5'
                >
                  <!-- form -->
                  <div class='d-flex flex-wrap'>
                    <b-col cols='12' md='4'>
                      <text-input
                        id='name'
                        v-model='form.title'
                        :label="$t('settings.notifications.title')"
                        rules='required'
                        :placeholder="$t('form.text.placeholder')"
                      />
                    </b-col>

                    <b-col cols='12' md='4'>
                      <wameed-input-file-upload
                        v-model='imageName'
                        :showDelete='true'
                        :delete-icon="'trash-icon'"
                        @deleteImage='deleteImage'
                        fileType='images'
                        field-classes='w-100'
                        rule='required'
                        functionType='front'
                        :label="$t('settings.notifications.image')"
                        :input-placeholder="$t('form.image.placeholder')"
                        :input-append-text="$t('form.image.browse')"
                        @changed='uploadBackgroundImage'
                      />
                    </b-col>
                    <b-col cols='12' md='12'>
                      <text-input
                        id='name'
                        v-model='form.body'
                        :label="$t('settings.notifications.body')"
                        rules='required'
                        :placeholder="$t('form.text.placeholder')"
                      />
                    </b-col>

                    <b-col cols='12' md='4'>
                      <wameed-dropdown
                        v-model='form.user_group'
                        :label="$t('settings.notifications.target')"
                        :placeholder="$t('settings.notifications.target')"
                        variant='disable'
                        :items='userGroupList'
                        class='wameed_dropdown'
                        title='name'
                        rules='required'
                        @input='onGroupChange'
                        :no_options="$t('common.no_options')"
                      />
                    </b-col>

                    <b-col cols='12' md='4' v-if='form.targets.length===0 && form.user_group.id == 5'>
                      <div class='d-flex' style='margin-top: 33.5px'>

                        <div class='mx-2  outline-link small  fill-gray' @click='showNotificationFilters'>
                          <add-gray-icon />
                          {{ $t('settings.notifications.add_filter') }}
                        </div>
                      </div>
                    </b-col>
                  </div>

                  <div class='d-flex flex-wrap mt-5' v-if='form.targets.length>0 && form.user_group.id == 5'>
                    <b-col cols='12' md='4'>
                      <div class='notification-filter-container'>
                        <div>
                          <h3 class='notification-filter-container--header'>{{ filterHeader }}</h3>
                          <p class='notification-filter-container--text'>{{ filterText }}</p>
                        </div>
                        <div class='notification-filter-container--icons'>
                          <s-filter-edit-icon @click='showNotificationFilters' />
                          <s-filter-delete-icon @click='deleteAllTargets' />
                        </div>
                      </div>
                    </b-col>
                  </div>
                  <wameed-switch
                    class='coupon-code-switch'
                    :checked='scheduled'
                    :title="$t('settings.notifications.scheduled')"
                    @onChange='toggleScheduled'
                  />

                  <div class='d-flex flex-wrap' v-if='scheduled'>
                    <b-col cols='12' md='4'>
                      <Wameed-date-picker
                        v-model='form.schedule_date'
                        placeholder='--:--'
                        mode='datetime'
                        :config='{"type":"string","mask":"YYYY/MM/DD H:mm"}'

                        :locale='locale'
                        :label="$t('settings.notifications.scheduled_date')"
                        rules='required'
                        :updateOnInput='true'
                      />
                    </b-col>
                  </div>

                </b-card-body>
                <b-card-footer class='px-4 py-3'>
                  <div class='d-flex flex-wrap justify-content-between'>
                    <div class='px-4 py-3'>
                      <wameed-btn
                        classes='  text-med-14 text-white rounded-10'
                        :title="$t('settings.notifications.send')"
                        :disabled='invalid || (form.user_group.id === 5 && form.targets.length===0)'
                        type='submit'
                        variant='main'
                      />
                    </div>

                    <div class='px-4 py-3'>
                      <wameed-btn
                        classes='  text-med-14 text-font-secondary rounded-10 '
                        :title="$t('common.cancel')"
                        type='button'
                        variant='gray'
                        @onClick='goBack'
                      />
                    </div>
                  </div>
                </b-card-footer>
              </div>
            </wameed-form>
          </b-card>
        </b-col>
      </b-row>
    </section>
    <notification-filter-modal
      :visible='showModal'
      @close='showModal = false'
      @onComplete='onSelectFilters'
    ></notification-filter-modal>

  </div>
</template>

<script>

import PageHeader from '@/components/wameed/WameedPageHeader.vue';
import { userTargets } from '@/enums/targetTypes.enum';
import { notificationTarget } from '@/enums/notificationTarget.enum';
import { WameedBtn, WameedDropdown, WameedForm, WameedTextInput as TextInput } from 'wameed-ui/dist/wameed-ui.esm';
import AdModal from '@/views/pages/ads/components/adModal.vue';
import NotificationFilterModal from '@/views/pages/settings/notifications/components/notificationFilterModal.vue';

export default {
  components: {
    NotificationFilterModal,
    AdModal,
    WameedBtn,
    WameedDropdown,
    WameedForm,
    TextInput,
    PageHeader
  },
  data() {
    return {
      scheduled: false,
      userGroupList: userTargets,
      imageName: '',
      showModal: false,
      key: 0,
      form: {
        title: '',
        user_group: '',
        targets: [],
        image: '',
        body: '',
        schedule_date: ''
      }


    };
  },

  computed: {
    locale() {
      return this.$i18n.locale;
    },
    filterHeader() {
      let users = this.form.targets.find(target => target.key === notificationTarget.users);
      if (users) {
        return this.$t('settings.notifications.filter.specific');
      }
      return this.$t('settings.notifications.filter.other');
    },
    filterText() {
      let text = '';

      let users = this.form.targets.find(target => target.key === notificationTarget.users);
      let age = this.form.targets.find(target => target.key === notificationTarget.age);
      let gender = this.form.targets.find(target => target.key === notificationTarget.gender);
      if (users) {
        text = users.value.length + ' ' + this.$t('settings.notifications.users');
        return text;
      }

      if (gender) {
        let value = gender.value;
        let _gender = this.$tc('common.males', 1);
        if (value.length === 1) {
          if (value[0] === 2) {
            _gender = this.$t('common.females');
          }
          text = _gender + ' ' + this.$t('common.only');
        } else {
          _gender += ', ' + this.$t('common.females');
          text = _gender;
        }
      }
      if (age) {
        let value = age.value;
        text += ' - ' + this.$t('common.from') + ' ' + value[0] + ' ' + this.$t('common.to') + ' ' + value[1] + ' ' + this.$t('common._year');
      }

      return text;
    }
  },
  methods: {
    onGroupChange(value) {
      console.log('value', value);
      this.form.user_group = value;
      this.form.targets = [];
    },
    toggleScheduled() {
      this.scheduled = !this.scheduled;
    },
    deleteAllTargets() {
      this.form.targets = [];
    },
    showNotificationFilters() {
      this.showModal = true;
    },
    goBack() {
      this.$router.push({ name: 'settings' });
    },
    uploadBackgroundImage(value) {
      this.form.image = [value.url];
      this.imageName = value.name;
    },

    deleteImage() {
      this.form.image = [];
      this.imageName = '';
    },
    onSelectFilters(filter) {

      console.log('filter', filter);
      let target = {};
      if (filter.option === 1) {
        target = {
          'key': notificationTarget.users,
          'value': filter.users
        };
        this._addTargetToForm(target);
      } else if (filter.option === 2) {
        target = {
          'key': notificationTarget.gender,
          'value': filter.gender
        };
        this._addTargetToForm(target);

        target = {
          'key': notificationTarget.age,
          'value': [filter.age.from, filter.age.to]
        };

        if (filter.age && filter.age.to && filter.age.from) {
          this._addTargetToForm(target);
        } else {
          this._removeTargetToForm(target);
        }

      }

    },


    _addTargetToForm(target) {

      const index = this.form.targets.findIndex(item => item.key === target.key);
      let _temp = this.form.targets.filter((item) => {
        return item.key !== target.key;
      });

      if (index === -1) {
        _temp.push(target);
      } else {
        _temp.splice(index, 0, target);
      }

      this.form.targets = _temp;
    },
    _removeTargetToForm(target) {

      let _temp = this.form.targets.filter((item) => {
        return item.key !== target.key;
      });


      this.form.targets = _temp;
    },
    submit() {

      const targets = [];
      targets.push({
        'key': notificationTarget.usersType,
        'value': this.form.user_group.id
      });
      this.form.targets.unshift(...targets);
      if (!this.scheduled) {
        this.form.schedule_date = '';
      }
      this.$store.dispatch('admin/notifications/send', this.form).then(() => {
        //   this.key++;
        //   this.imageName = '';
        //   this.form = {
        //     title: '',
        //     targets: [],
        //     image: '',
        //     body: '',
        //     schedule_date: ''
        //   };
        this.$router.push({
          name: 'system-notifications'
        });
      });


    }
  },
  created() {
    this.$store.dispatch('admin/notifications/getUsers');
  }
};
</script>

